<template>
    <BaseCard
        :entry="entry"
        :is-inline="isInline"
        class="page-card"
    >
        <template
            v-if="!image"
            #image
        >
            <BaseIcon
                icon="chevron-down"
                class="page-card__icon"
            />
        </template>
    </BaseCard>
</template>

<script setup>
import BaseCard from '@/components/BaseCard/BaseCard.vue';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    isInline: {
        type: Boolean,
        default: false
    }
});

const { featuredImage } = toRefs(props.entry);
const image = featuredImage?.value?.[0];
</script>

<style lang="less" src="./PageCard.less" />
